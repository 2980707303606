.contact-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

h4 {
    margin: 6px 0;
}

.acc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1200px;
    margin: auto;
}
.acc-wrapper .MuiPaper-root{
    border-radius: 16px !important ;
    
}
.acc-wrapper .MuiPaper-root.MuiAccordion-root {
    border: 1px var(--accent-secondary) solid;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 50px auto;

}

.contact-wrapper .MuiOutlinedInput-root {
    border-radius: 16px !important;
}
.contact-wrapper .MuiTextField-root {
    margin-top: 4px;
}

.contact-wrapper .MuiBox-root {
    padding: 0;
    width: 100%;
}

.status-msg {
    margin-top: 4px;
    padding: 1px 16px;
    border-radius: 16px;
    margin-bottom: 30px;
}

.success {
    box-shadow: 0px 0px 4px 0px var(--accent-green);
}

.fail {
    color: var(--accent-red);
    box-shadow: 0px 0px 4px 0px var(--accent-red);
}
@media only screen and (max-width: 1200px) {
    .acc-wrapper, .form-wrapper {
        padding: 0 30px;;
    }
}

@media only screen and (max-width: 768px) {
    .form-wrapper {
        margin: 0px;
    }



}
