.video-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-button {
    background-color: var(--accent);
    border: none;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    transition: all 0.5s;
    margin-bottom: 20px;
}

.video-button:hover {
    transform: scale3d(1.01, 1.01, 1);
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.video-wrapper .videoWrapper {
    position: relative;
    width: 100%; /* Set width to 100% */
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: none;
}

.video-wrapper.show-video .videoWrapper {
    display: block;
    margin-bottom: 20px;
}

.video-wrapper .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videos {
    max-width: 1200px;
    margin: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    color: var(--accent);
}

.video {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.videoWrapper iframe {
    width: 100%;
    height: 100%;
    display: block;
    min-width: 560px;
    min-height: 315px;

  }
@media only screen and (max-width: 1200px) {
    .videoWrapper iframe {
        min-width: 400px;
        min-height: 225px;
      }
}
@media only screen and (max-width: 768px) {
    .videos {
      width: 100vw;
      flex-direction: column;
    }
    .videoWrapper iframe {
        min-width: 100vw;
      }
}