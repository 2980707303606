.wrapper-fee {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
}
.wrapper-fee p {
    margin: 0px 10px;
    text-align: center;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ol {
    margin: 10px;
}
li {
    padding-bottom: 10px;
}
.info {
    padding: 20px;
    margin: 0 20px 0 20px;
}

.greytext {
    color: var(--text-secondary);
}
.textButton {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: var(--accent);
    font-weight: 700;
    padding: 0;
    border-bottom: solid 2px var(--accent);
}
.tableWrap {
    overflow-x: scroll;
}

table {
    border: 2px solid var(--accent);
    margin: 25px 0;
    text-align: left;
    border-collapse: collapse;
    white-space: pre-line;  
    vertical-align: bottom;
}
tr {
    vertical-align: baseline;
}
th {
    background-color: var(--accent);
    color: #ffffff;
    padding: 2px 2px;
    max-width: 350px;
}

td {
    max-width: 350px;
    padding: 4px 6px;
    border: 1px solid var(--text-secondary);
    font-size: smaller;
}

.tag {
    background-color: var(--accent-green);
    border-radius: 8px;
    padding: 0px 4px;
}

.red {
    background-color: var(--accent-red);
    color: white
}

.miniHeading {
    text-shadow: 2px 2px var(--accent);
}
.indented {
    margin-left: 30px;
}
#table3 td, .fullTable td {
    max-width: max-content;
}

tr:nth-of-type(even) {
    background-color: white;
}
tr:last-of-type {
    border-bottom: 2px solid var(--accent);
}

@media only screen and (max-width: 768px) {
    #table1 {
        min-width: 700px;
        overflow-x: auto;
    }
    .fullTable {
        min-width: 100%;
        overflow-x: auto;
    }
    .info {
        padding: 5px;
    }
}