.container-loading {
    width: 100vw;
    height: 100vh;
}
.wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-wrapper {
    max-width: 500px;
    max-height: 500px;
    border-radius: 16px;
    background-color: var(--accent);
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero {
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding-bottom: 50px;

}

.hero-info {
    width: 50%;
    padding: 10px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

}
.hero-img-wrapper {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
}

.hero-img {
    max-height: 600px;
    padding: 50px 0;
    margin: 0px;
    transition: all 0.3s ease-out;
}
.hero-img:hover {
    transform: scale3d(1.02, 1.02, 1);
}
.hero-img-wrapper::before {
    content: " ";
    width: 45em;
    height: 45em;
    border-radius: 50%;
    background-color: var(--accent);
    position: absolute;
    top: -10em;
    right:  -15em;
    z-index: -10;
    overflow-x: hidden;
}

.download-logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 50px;
}
.download-logo a img{
    height: 50px;
    transition: all 0.3s ease-out;
}
.download-logo a img {
    border-radius: 16px;
}

.download-logo a img:hover {
    transform: scale3d(1.02, 1.02, 1);
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.hero-info a {
    text-decoration: none;
}
.video-player-button {
    border-radius: 30px;
    background-color: var(--yellow);
    border: none;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 200px;
    transition: all 0.5s;
    
    

}
.video-player-button:hover {
    transform: scale3d(1.01, 1.01, 1);
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    background-color: var(--accent);
}
.counter-wrapper {
    width: 100vw;
    background-color: var(--accent);
    padding: 50px 0;
}


.counters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    padding: 50px 30px;
    margin: auto;
    color: white;
}
.counter {
    min-width: 100px;
}
.counter h3 {
    text-transform: uppercase;
}
.number-counter {
    font-size: 3rem;
    font-weight: 700;
}

.features-wrapper {
    width: 100vw;
    background-color: var(--accent-opaque);
    padding: 100px 0;
    text-align: center;
}

.features {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}

.features-img {
    padding-top: 30px;
    max-height: 200px;
}

.features-card {
    width: 300px;
    height: 350px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
}
.features-card:hover {
    transform: scale3d(1.01, 1.01, 1);
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.features-info {
    padding: 30px 50px;
    text-align: center;
}

.whys-wrapper {
    width: 100vw;
    padding: 100px 0;
    text-align: center;
}

.whys {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px; 
    align-items: center;
    max-width: 1200px;
    margin: auto;
}
.whys-card {
    display: flex;
    margin: auto;
    gap: 30px;
}

.whys-img {
    height: 300px;
    transition: all 0.3s ease-out;
    background-color: white;
    border-radius: 30px;
}
.whys-img:hover {
    transform: scale3d(1.02, 1.02, 1);
}

.whys-info {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.whys-info p {
    color: var(--text-secondary);
}
.reverse {
    display: flex;
    flex-direction: row-reverse;
}

.reverse .whys-info {
    text-align: right;
    align-items: end;
}

.only-mobile {
    display: none;
}

.underlined {
    text-decoration: underline;
    text-decoration-color: var(--accent);
    text-decoration-thickness: 3px;
    transition: all 0.3s ease-out;
    text-underline-offset: 4px;
}
.underlined:hover {
    text-decoration-color: var(--yellow);
}

@media only screen and (max-width: 1200px) {
    .hero {
        max-width: 100%;
    }
    .hero-info {
        padding:0 30px;
    }
    .hero-img {
        max-width: 550px;
    }
}
.main-h2 {
    margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
    .main-h2 {
        margin-bottom: 40px;
    }
    .download-logos {
        margin-top: 40px;
    }
    .hero h1 {
        margin-top: 30px;
    }
    .hero {
      flex-direction: column;
      overflow: hidden;
      padding-bottom: 0px;
      /* height: 50vh; */
    }

    .hero-info {
        padding: 0 50px;
        margin: auto;
        width: 80%;
        align-items: center;
        text-align: center;
        
    }
    .hero-img-wrapper {
        /* margin: 40px 0; */
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .hero-img {
        /* max-height: 500px; */
        display: none;
    }
    .hero-img-wrapper::before {
        width: 35em;
        height: 35em;
        top: -5em;
        right:  -14em;

    }
    .features {
        flex-direction: column;
    }
    .features-card {
        width: 350px;
    }
    .whys {
        gap: 50px;
    }
    .whys-card {
        flex-direction: column;
        gap: 0px;
        align-items: center;
    }
    .whys-info, .reverse .whys-info{
        width: 305px;
        align-items: center;
        text-align: center;
        margin-top: 30px;
    }

}

@media only screen and (max-width: 512px) {
    .hero {
      flex-direction: column;
    }
    .hero-info {
        padding: 0 20px;
    }
    .hero-img-wrapper::before {
        width: 25em;
        height: 25em;
        top: -4em;
        right:  -10em;
    }
}

