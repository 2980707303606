.App {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.drawer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 20px;
  gap: 20px;
  min-width: 200px;
}
.drawer-toggle-button {
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  display: none;
}
nav {
  background-color: white;
  width: 100vw;
  z-index: 2;
  margin: 0;
  padding: 0;
  display: flex;
}
.nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--accent-opaque);
  align-items: center;
  height: 100%;
  max-width: 1200px;
  margin: auto;
}

a {
  color: var(--accent);
  font-weight: 700;
}

.link{
  color: black;
  margin: 4px 10px;
  text-decoration: none;
  font-weight: normal;
  vertical-align: top;
}

.link:hover {
  color: var(--accent);
  font-weight: 700;
}
.active-link {
  font-weight: 700;
  color: var(--accent);
  margin: 4px 10px;
  text-decoration: none;
  vertical-align: top;
}
nav .main {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}
nav .main *{
  height: 100%;
}
.tabs {
  width: 50vw;
  margin: auto;
}
.tabcontent {
  margin: 16px;
  
}

.customtab {
font-weight: 700;
padding: 0px;
margin: 0px;
}

footer {
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  background-color: white;
  position: sticky;
  bottom: 0px;
}
.footer-download {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: black;
}
.logo-img {
  height: 80px;
  width: 80px;
}
.bb-logo-img {
  height: 30px;
  width: 100%;
  
}
.footer-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1200px;
  margin: auto;
  padding: 40px 0;
  gap: 40px;
}
.footer-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-download .download-logos {
  margin-top: 20px;
 }

.footer-menu {
  display: flex;
  gap: 20px;
}
.footer-menu * {
  color: black;
  text-decoration: none;
  font-weight: normal;
}
.link-policy {
  color: var(--accent);
}

/* FONTS */
h1 {
  font-size: 3.5rem;
  margin-bottom: 16px;
  margin-top: 80px;
}
h2 {
  margin-top: 0;
  font-size: 2rem;


}
.business-info { 
  width: 100vw;
  max-width: 1200px;
  text-align: center;
  font-size: smaller;
  border-top: 1px solid var(--accent-opaque);
  padding-top: 40px;
  margin: auto;
}
.gradient {
  background: linear-gradient(135deg, #3e789d 1%,#86c2de 28%,#86c2de 54%,#ffd16f 73%,#ffd16f 86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero h3 {
  font-weight: normal;
}
 h3 {
  margin: 0;
  font-size: 1.5rem;
 }
 @media only screen and (max-width: 1200px) {
  .nav-wrapper, .footer-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
 }
 @media only screen and (max-width: 768px) {
  .tabs {
    width: 100vw;
  }
  .nav-wrapper {
    padding: 10px 20px;
  }
  .footer-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .nav-menu {
    display: none;
  }
  .drawer-toggle-button {
    display: flex;
  }

  }
@media only screen and (max-width: 512px) {
  h1 {
      font-size: 2.5rem;
      margin: 40px 0px;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  .footer-inner-wrapper {
    flex-direction: column;
    gap: 40px;
  }
  .footer-menu {
    flex-direction: row;

  }
  nav .main {
    margin: 0;
  }
  
}