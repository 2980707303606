.process-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    text-align: center;


}

.process {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 100px auto;
    padding-top: 50px;
    gap: 50px;
    position: relative;
}
.process::before {
    content: " ";
    background-color: var(--text-secondary);
    width: 2px;
    height: 100%;
    position: absolute;
    left:calc(50% - 1px);
}
.process .step:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
}
.step {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
    position: relative;

}
.step::before {
    content: " ";
    background-color: var(--accent);
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100%;
    left:calc(50% - 10px);
    top: 0px;
}
.step-num {
    list-style-type: none;
    font-size: 8rem;
    color: var(--yellow);
    font-weight: 900;
}

.step-img {
    border-radius: 30px;
    min-height: 350px;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
}
.step-img:hover {
    transform: scale3d(1.02, 1.02, 1);
}
.step-image {
    max-height: 400px;

} 
.process .step:nth-child(even) .step-info {
    text-align: right;
}
.process .step:last-child::after {
    content: " ";
    background-color: var(--accent-green);
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100%;
    left:calc(50% - 10px);
    bottom: 0px;
}
.step-info {
    text-align: left;
    width: 400px;
}

.step-info p {
    color: var(--text-secondary);
}
.step-tag {
    background-color: var(--yellow-opaque);
    padding: 6px 10px;
    border-radius: 8px;
}
.text-button {
    background-color: transparent;
    border: none;

}
@media only screen and (max-width: 1200px) {
    .step-img, .step-info, .step-image {
        max-height: 400px;
        max-width: 300px;
    }

   }
@media only screen and (max-width: 768px) {
    .process::before {
        left: -50px;
    }
    .step::before {
        left: -60px;
        top: 0px;
    }
    .process .step:last-child::after {
        left: -60px;
    }
    .process {
        margin-top: 0px;
    }
    .step, .process .step:nth-child(even) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .step, .process .step:nth-child(even) .step-info {
        text-align: left;
    }
    .step-num {
        position: absolute;
        right: -30px;
        top: 55%;
        z-index: 10;
    }

    }
@media only screen and (max-width: 512px) {
    .process::before, .step::before, .step:last-child::after {
        visibility: hidden;
    }
    .step {
        gap: 0px;
    }
    .step-num {
        position: relative;
        right: 0px;
        font-size: 4rem;
    }
  }